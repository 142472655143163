import { GlobalState, GlobalActionTypes } from './types';

const initialState: GlobalState = {

}

export function globalReducer(state = initialState, action: GlobalActionTypes,): GlobalState {
  switch (action.type) {
    default:
      return state
  }
}
