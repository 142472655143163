import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import globalStore, { persistor } from '@/store'

import Routes from '@/routes'
function App() {
  return (
    <ReduxProvider store={globalStore}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
