import React, { Component } from 'react';

import { NotFoundLayout } from './index'

class NotFoundContainer extends Component {
  render() {
    return (
      <NotFoundLayout />
    );
  }
}

export default NotFoundContainer;
