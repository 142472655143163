

import { mergeDeep } from '@/utils/objects'
import { default as localeEn } from './en/translation.json'
import { default as localeEs } from './es/translation.json'

type localeType = {
  [name: string]: string | localeType
}

type languageType = {
  translation: localeType
}

/**
 * Generate and merge i18n language objects
 * @param locale locale JSON object from /src/resources/locales/[localeName: string]/translate.json
 * @param [fallbackLang] i18n language object for fallback
 * @returns lang, a i18n language object
 */
function mergeLangs(locale: localeType, fallbackLang?: languageType): languageType {

  let resultLang: languageType = {
    translation: {}
  }

  if (fallbackLang) {
    resultLang.translation = mergeDeep(fallbackLang?.translation, locale)
  } else {
    resultLang.translation = locale
  }

  return resultLang
}

// EN language: as default, no merges, no fallbacks
const en: languageType = mergeLangs(localeEn)

// ES language: merge with EN language as fallback
const es: languageType = mergeLangs(localeEs, en)

export default {
  en,
  es,
}
