import React from "react"
import { Route, Switch } from "react-router-dom"

import * as env from '@/config/globals'
import { getCountry } from "@/utils/objects"

const DefaultSite = React.lazy(() => import("@/components/sites/kuepa"))

const landingRoutesConfig: any = {
  impactReport: {
    path: '/',
    component: React.lazy(() => import("@/components/sites/impactReport")),
  },
  kuepaHome: {
    path: '/',
    component: React.lazy(() => import("@/components/sites/kuepa")),
  },
  hiBogota: {
    path: '/',
    component: React.lazy(() => import('@/components/sites/hiBogota'))
  },
  nuevaAmerica: {
    path: '/',
    component: React.lazy(() => import('@/components/sites/nuevaAmerica'))
  },
  kuepaEduTech: {
    path: '/',
    component: React.lazy(() => import('@/components/sites/kuepaEduTech'))
  },
  techPower: {
    path: '/',
    component: React.lazy(() => import('@/components/sites/techPower'))
  },
  manzanillo: {
    path: '/',
    component: React.lazy(() => import('@/components/sites/manzanillo'))
  },
  kuepaedu: {
    path: '/',
    component: React.lazy(() => import('@/components/sites/kuepa_edu'))
  },
}


const siteRoutes = () => {

  const currentHost: string = window.location.hostname
  const isLocalhost: boolean = currentHost === 'localhost' || currentHost === '127.0.0.1'
  const isHost: boolean = currentHost === env?.host
  const currentAvailableHost: env.AvailableHost = env.available_hosts[currentHost]
  if ((currentAvailableHost?.slug === 'kuepaHome' || isLocalhost) && ['mexico', 'guatemala', 'salvador', 'ecuador', 'peru']?.some((country: string) => getCountry()?.toLowerCase()?.includes(country))) {
    window.location.href = 'https://universidaduk.com/';
    return <></>
  } else {
    return (
      <React.Suspense fallback={React.Fragment}>
        <Switch>
          {env?.available_hosts && Object.keys(env?.available_hosts).length > 0 && !isLocalhost && !isHost ? (
            <React.Fragment>
              <Route
                path={landingRoutesConfig[currentAvailableHost?.slug].path}
                component={landingRoutesConfig[currentAvailableHost.slug].component}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Route path="/" component={DefaultSite} />
            </React.Fragment>
          )}
        </Switch>
      </React.Suspense>
    )
  }
}

export default siteRoutes
