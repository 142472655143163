import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import * as env from '@/config/globals'
import appRoutes from './appRoutes'
import siteRoutes from './siteRoutes'

import NotFoundScreen from "@/components/screens/notFound";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>

        {env?.has_site === true && env?.secure_home && env?.secure_home !== "/" ? (
          <React.Fragment>
            <Route path={env?.secure_home} component={appRoutes} />
            <Route path="/" component={siteRoutes} />
          </React.Fragment>
        ) : (
          <Route path="/" component={appRoutes} />
        )}

        <Route path="*" component={NotFoundScreen} />
      </Switch>
    </BrowserRouter>
  )
}


export default AppRouter;
