export function mergeDeep(origin: any, source: any) {
  const target: any = Object.assign({}, origin)

  const isObject = (obj: any) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export function getCountry() {
  let countries: any = {
    MX: "Mexico",
    PE: "Peru",
    SV: "El Salvador",
    GT: "Guatemala",
    EC: "Ecuador",
    AR: "Argentina",
  };
  let timezones: any = {
    "America/Rosario": {
      a: "America/Argentina/Cordoba",
      r: 1
    },
    "America/Mendoza": {
      a: "America/Argentina/Mendoza",
      r: 1
    },
    "America/Jujuy": {
      a: "America/Argentina/Jujuy",
      r: 1
    },
    "America/Cordoba": {
      a: "America/Argentina/Cordoba",
      r: 1
    },
    "America/Catamarca": {
      a: "America/Argentina/Catamarca",
      r: 1
    },
    "America/Buenos_Aires": {
      a: "America/Argentina/Buenos_Aires",
      r: 1,
    },
    "America/Argentina/Buenos_Aires": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/Catamarca": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/ComodRivadavia": {
      a: "America/Argentina/Catamarca",
      r: 1
    },
    "America/Argentina/Cordoba": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/Jujuy": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/La_Rioja": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/Mendoza": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/Rio_Gallegos": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/Salta": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/San_Juan": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/San_Luis": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/Tucuman": {
      u: -180,
      c: ["AR"]
    },
    "America/Argentina/Ushuaia": {
      u: -180,
      c: ["AR"]
    },
    "Mexico/BajaNorte": {
      a: "America/Tijuana",
      r: 1
    },
    "Mexico/BajaSur": {
      a: "America/Mazatlan",
      r: 1
    },
    "Mexico/General": {
      a: "America/Mexico_City",
      r: 1
    },
    "America/Mexico_City": {
      u: -360,
      d: -300,
      c: ["MX"]
    },
    "America/Lima": {
      u: -300,
      c: ["PE"]
    },
    "America/Guayaquil": {
      u: -300,
      c: ["EC"]
    },
    "Pacific/Galapagos": {
      u: -360,
      c: ["EC"]
    },
    "America/El_Salvador": {
      u: -360,
      c: ["SV"]
    },
    "America/Guatemala": {
      u: -360,
      c: ["GT"]
    },
  };
  let timezone: any = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log('Enodrac - Intl', Intl)
  console.log('Enodrac - Intl.DateTimeFormat()', Intl.DateTimeFormat())
  console.log('Enodrac - Intl.DateTimeFormat().resolvedOptions()', Intl.DateTimeFormat().resolvedOptions())
  console.log('Enodrac - timezone', timezone)
  if (timezone === "" || !timezone) {
    return '';
  }
  let countryElement = timezones[timezone];
  let foundCountry: any
  if (countryElement?.c) {
    foundCountry = countries[countryElement?.c[0]]
  } else {
    let posibleCountries = countryElement?.a ? countryElement?.a?.split('/')?.map((item: any) => item?.toLowerCase()?.replace('_', ' ')) : []
    let splitTimezone = timezone?.split('/')?.map((item: any) => item?.toLowerCase()?.replace('_', ' '))
    Object.values(countries)?.forEach((country: any) => {
      country = country.toLowerCase()
      if (!foundCountry) {
        if (posibleCountries?.includes(country) || splitTimezone?.includes(country)) {
          foundCountry = country
        }
      }
    })
  }
  return foundCountry || '';
}
